*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.5;
  font-family: var(--fontFamily-sans-0), var(--fontFamily-sans-1), var(--fontFamily-sans-2), var(--fontFamily-sans-3);
  font-feature-settings: normal;
  font-variation-settings: normal;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --fontFamily-sans-0: Karla;
  --fontFamily-sans-1: Barlow;
  --fontFamily-sans-2: ui-sans-serif;
  --fontFamily-sans-3: system-ui;
  --fontFamily-titlefont-0: Barlow;
  --fontFamily-karla-0: Karla;
  --lineHeight-jobtable: 1.375;
  --fontSize-sm-0: .875rem;
  --fontSize-sm-1-lineHeight: 1.25rem;
  --fontSize-lg-0: 1.125rem;
  --fontSize-lg-1-lineHeight: 1.75rem;
  --colors-pagebackground: 224, 242, 254;
  --colors-maintext: 15, 23, 42;
  --colors-jobhover: 253, 224, 71;
  --colors-jobhovertext: 15, 23, 42;
  --colors-themetext: 159, 18, 57;
  --colors-searchbox: 254, 240, 138;
  --colors-searchtext: 2, 6, 23;
}

.terminal {
  --fontFamily-sans-0: FlexiIBMVGA;
  --fontFamily-sans-1: ui-sans-serif;
  --fontFamily-sans-2: system-ui;
  --fontFamily-titlefont-0: FlexiIBMVGA;
  --fontSize-sm-0: .875rem;
  --fontSize-sm-1-lineHeight: 1.25rem;
  --fontSize-lg-0: 1.125rem;
  --fontSize-lg-1-lineHeight: 1.75rem;
  --colors-pagebackground: 3, 7, 18;
  --colors-maintext: 74, 222, 128;
  --colors-jobhover: 74, 222, 128;
  --colors-jobhovertext: 3, 7, 18;
  --colors-searchbox: 3, 7, 18;
  --colors-searchtext: 74, 222, 128;
}

.slotglass {
  --fontFamily-sans-0: MontereyBold;
  --fontFamily-sans-1: ui-sans-serif;
  --fontFamily-sans-2: system-ui;
  --fontFamily-titlefont-0: MontereyBold;
  --fontSize-sm: .9rem;
  --fontSize-lg: 1.15rem;
  --lineHeight-jobtable: 1.5;
  --colors-pagebackground: 236, 253, 245;
  --colors-maintext: 168, 85, 247;
  --colors-jobhover: 5, 150, 105;
  --colors-jobhovertext: 233, 213, 255;
  --colors-searchbox: 167, 243, 208;
  --colors-searchtext: 126, 34, 206;
}

.neon {
  --fontFamily-sans-0: Beon;
  --fontFamily-sans-1: ui-sans-serif;
  --fontFamily-sans-2: system-ui;
  --fontFamily-titlefont-0: Beon;
  --lineHeight-jobtable: 1.5;
  --colors-pagebackground: 49, 46, 129;
  --colors-maintext: 255, 0, 255;
  --colors-jobhover: 3, 7, 18;
  --colors-jobhovertext: 0, 255, 255;
  --colors-searchbox: 216, 180, 254;
  --colors-searchtext: 88, 28, 135;
}

.sportsbook {
  --fontFamily-sans-0: FridayNightLights;
  --fontFamily-sans-1: ui-sans-serif;
  --fontFamily-sans-2: system-ui;
  --fontFamily-titlefont-0: FridayNightLights;
  --colors-pagebackground: 55, 65, 81;
  --colors-maintext: 251, 191, 36;
  --colors-jobhover: 9, 9, 11;
  --colors-searchbox: 156, 163, 175;
  --colors-searchtext: 22, 101, 52;
}

@font-face {
  font-family: Karla;
  font-weight: 100 1000;
  src: url("Karla-VariableFont_wght.6d7a17b2.ttf") format("truetype");
}

@font-face {
  font-family: Karla;
  font-style: italic;
  font-weight: 100 1000;
  src: url("Karla-Italic-VariableFont_wght.adaeca5b.ttf") format("truetype");
}

@font-face {
  font-family: Barlow;
  font-weight: 800;
  src: url("Barlow-ExtraBold.ca635dcf.ttf") format("truetype");
}

@font-face {
  font-family: FlexiIBMVGA;
  src: url("Flexi_IBM_VGA_False_437.697e93b5.ttf") format("truetype");
}

@font-face {
  font-family: MontereyBold;
  src: url("MontereyFLF_Bold.32398b18.woff2") format("woff2"), url("MontereyFLF_Bold.d53dfaaa.ttf") format("truetype");
}

@font-face {
  font-family: FridayNightLights;
  src: url("DJB_Friday_Night_Lights.414ce365.woff2") format("woff2"), url("DJB_Friday_Night_Lights.84af6885.woff") format("woff"), url("DJB_Friday_Night_Lights.b6e235e6.ttf") format("truetype");
}

@font-face {
  font-family: Beon;
  src: url("Beon_Medium.57981c06.woff2") format("woff2"), url("Beon_Medium.48792029.woff") format("woff");
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.neon .joblist {
  text-shadow: 0 0 8px #f9a8d4, 0 0 16px #fbcfe8;
}

.neon .header {
  text-shadow: 0 0 16px #f9a8d4, 0 0 32px #fbcfe8, 0 0 48px #fce7f3;
}

.sportsbook {
  text-transform: uppercase;
}

.terminal .search input {
  caret-shape: block;
}

.jobgrid .tr {
  grid-template-columns: 3fr 5fr 12fr;
  width: 100%;
  display: grid;
}

div, span {
  max-height: 9999px;
}

.slowconn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 6s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.static {
  position: static;
}

.relative {
  position: relative;
}

.m-3 {
  margin: .75rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.w-15p {
  width: 15%;
}

.w-25p {
  width: 25%;
}

.w-5\/10 {
  width: 50%;
}

.w-6\/10 {
  width: 60%;
}

.w-full {
  width: 100%;
}

.min-w-15p {
  min-width: 15%;
}

.min-w-25p {
  min-width: 25%;
}

.max-w-5\/10 {
  max-width: 50%;
}

.max-w-6\/10 {
  max-width: 60%;
}

.table-fixed {
  table-layout: fixed;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-\[3fr_7fr_10fr\] {
  grid-template-columns: 3fr 7fr 10fr;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.gap-x-12 {
  -moz-column-gap: 3rem;
  column-gap: 3rem;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.border-2 {
  border-width: 2px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.bg-pagebackground {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--colors-pagebackground), var(--tw-bg-opacity));
}

.bg-searchbox {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--colors-searchbox), var(--tw-bg-opacity));
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.font-karla {
  font-family: var(--fontFamily-karla-0);
}

.font-sans {
  font-family: var(--fontFamily-sans-0), var(--fontFamily-sans-1), var(--fontFamily-sans-2), var(--fontFamily-sans-3);
}

.font-titlefont {
  font-family: var(--fontFamily-titlefont-0);
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: var(--fontSize-lg-0);
  line-height: var(--fontSize-lg-1-lineHeight);
}

.text-sm {
  font-size: var(--fontSize-sm-0);
  line-height: var(--fontSize-sm-1-lineHeight);
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-jobtable {
  line-height: var(--lineHeight-jobtable);
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-maintext {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-maintext), var(--tw-text-opacity));
}

.text-searchtext {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-searchtext), var(--tw-text-opacity));
}

.text-themetext {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-themetext), var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:bg-jobhover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--colors-jobhover), var(--tw-bg-opacity));
}

.hover\:text-jobhovertext:hover {
  --tw-text-opacity: 1;
  color: rgba(var(--colors-jobhovertext), var(--tw-text-opacity));
}

.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.terminal .terminal\:rounded-none {
  border-radius: 0;
}

.terminal .terminal\:border-0 {
  border-width: 0;
}

.terminal .terminal\:border-b-2 {
  border-bottom-width: 2px;
}

.terminal .terminal\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.terminal .terminal\:text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.terminal .terminal\:tracking-tight {
  letter-spacing: -.025em;
}

.terminal .terminal\:hover\:font-bold:hover {
  font-weight: 700;
}

.neon .neon\:text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.sportsbook .sportsbook\:mt-6 {
  margin-top: 1.5rem;
}

.sportsbook .sportsbook\:text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.sportsbook .sportsbook\:text-\[0\.60rem\] {
  font-size: .6rem;
}

.sportsbook .sportsbook\:text-\[0\.625rem\] {
  font-size: .625rem;
}

.sportsbook .sportsbook\:text-\[0\.75rem\] {
  font-size: .75rem;
}

.sportsbook .sportsbook\:text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.sportsbook .sportsbook\:uppercase {
  text-transform: uppercase;
}

.sportsbook .sportsbook\:not-italic {
  font-style: normal;
}

.sportsbook .sportsbook\:leading-relaxed {
  line-height: 1.625;
}

.sportsbook .sportsbook\:tracking-wide {
  letter-spacing: .025em;
}

.sportsbook .sportsbook\:tracking-wider {
  letter-spacing: .05em;
}

.sportsbook .sportsbook\:text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.sportsbook .sportsbook\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.sportsbook .sportsbook\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:hidden {
    display: none;
  }

  .md\:grid-cols-\[3fr_5fr_12fr\] {
    grid-template-columns: 3fr 5fr 12fr;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: var(--fontSize-lg-0);
    line-height: var(--fontSize-lg-1-lineHeight);
  }

  .terminal .terminal\:md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .terminal .terminal\:md\:tracking-normal {
    letter-spacing: 0;
  }

  .sportsbook .sportsbook\:md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sportsbook .sportsbook\:md\:text-\[0\.75rem\] {
    font-size: .75rem;
  }

  .sportsbook .sportsbook\:md\:text-\[0\.95rem\] {
    font-size: .95rem;
  }

  .sportsbook .sportsbook\:md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 1024px) {
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

@media (width >= 1280px) {
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

/*# sourceMappingURL=index.f8a712b0.css.map */
